<template>
  <div class="custom-modal-content">
    <div class="custom-modal-title ">
      {{ $t('analyticsQueryModals.analyticsDeleteSavedQueryModalTitle', {queryName: savedQuery.name}) }}
    </div>
    <div class="custom-modal-body">
      <p>{{ $t('analyticsQueryModals.analyticsDeleteSavedQueryModalText') }}</p>
    </div>
    <div class="custom-modal-footer">
      <div
        class="new-action-button new-button delete-saved-query-btn"
        @click="deleteQuery()"
      >
        {{ $t('delete') }}
      </div>
      <div
        class="new-cancel-button new-button delete-saved-query-btn"
        @click.stop="close()"
      >
        {{ $t('cancel') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  ANALYTICS_DELETE_SAVED_QUERY,
  ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION
} from '@/store/modules/analytics/outcomes-explorer/constants'
import { ModalMixin } from '@/mixins/modal/ModalMixin'

export default {
  name: 'AnalyticsDeleteSavedQueryModal',
  mixins: [ModalMixin],
  computed: {
    ...mapGetters({
      savedQuery: 'getAnalyticsSelectedSavedQueryForEdition'
    })
  },
  methods: {
    ...mapActions({
      deleteSavedQueryAction: ANALYTICS_DELETE_SAVED_QUERY
    }),
    ...mapMutations({
      setSavedQueryForEdition: ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION
    }),
    deleteQuery () {
      const loading = this.$loading.show()
      this.deleteSavedQueryAction(this.savedQuery.id).then(() => {
        this.close()
        this.setSavedQueryForEdition(null)
        loading.hide()
      })
    }
  }
}
</script>
