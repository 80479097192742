<template>
  <div class="custom-modal-content">
    <div class="custom-modal-title">
      <span>{{ $t('measureVariesByPatient') }}</span>
    </div>
    <div class="custom-modal-body">
      <span>{{ $t('previewFor') }}</span>
      <input
        v-model="patientAge"
        type="text"
        class="form-control-sm input-age"
      >
      <span>{{ $t('yearsOldPatient') }}</span>
    </div>
    <span class="error">{{ patientAgeErrorMessage }}</span>
    <div class="custom-modal-footer">
      <div
        class="new-cancel-button new-button"
        @click="close()"
      >
        {{ $t('cancel') }}
      </div>
      <div
        class="new-action-button new-button"
        @click="openPreview()"
      >
        {{ $t('startPreview') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  ACTION_CLOSE_AUDITION_ENHANCE_MODAL,
  MUTATION_SET_ENHANCE_AUDITION_VARIABLES
} from '@/store/modules/applicationState/constants'

export default {
  name: 'EnhanceAuditionModalContent',
  data () {
    return {
      patientAge: null,
      showPatientAgeError: false,
      patientAgeErrorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      auditionEnhanceData: 'getEnhanceAuditionData'
    })
  },
  watch: {
    patientAge () {
      this.patientAgeErrorMessage = null
    }
  },
  methods: {
    ...mapActions({
      close: ACTION_CLOSE_AUDITION_ENHANCE_MODAL
    }),
    ...mapMutations({
      setEnhanceVariables: MUTATION_SET_ENHANCE_AUDITION_VARIABLES
    }),
    openPreview () {
      if (!this.patientAge) {
        this.showPatientAgeError = true
        this.patientAgeErrorMessage = this.$t('patientAgeEmptyError')
        return
      }

      const regex = /[0-9]+/
      if (!regex.test(this.patientAge)) {
        this.showPatientAgeError = true
        this.patientAgeErrorMessage = this.$t('patientAgeInvalidError')
        return
      }

      this.patientAgeErrorMessage = null

      const enhanceVariables = {
        'demo-age': this.patientAge,
        'mes-completed-age': this.patientAge
      }
      this.setEnhanceVariables(enhanceVariables)
      this.$router.push({ name: 'AuditionMeasureQuestionnaire', params: { measureId: this.auditionEnhanceData.id } })
      this.close()
    }
  }
}
</script>
