<template>
  <div class="delete-location-modal">
    <div class="delete-location-modal-content">
      <p>{{ $t('areYouSureToDelete') }} <strong>{{ locationEditing.name }}?</strong></p>
      <p>{{ $t('deleteLocationText.1') }}</p>
      <div v-if="locationHasClinicians">
        <p>{{ $t('deleteLocationText.2') }}</p>
        <div class="form-group">
          <select
            id="reasignLocation"
            v-model="reasignLocationId"
            name="reasignLocation"
            class="form-control"
          >
            <option
              value=""
              selected="selected"
            >
              {{ $t('reAssignClinicalUsers') }}
            </option>
            <option
              v-for="location in filteredLocations"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="delete-location-modal-footer">
      <button
        type="button"
        class="btn btn-square btn-owl-cancel"
        @click="closeModal"
      >
        {{ $t('cancel') }}
      </button>
      <button
        type="button"
        class="btn btn-square btn-owl-delete"
        @click="deleteLocation"
      >
        {{ $t('yesDelete') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { MUTATION_SET_MODAL_OPENED, MUTATION_SET_MODAL_TEMPLATE } from '@/store/modules/applicationState/constants'
import { DELETE_LOCATION, EDITING_SETTING, LOCATION_RE_ASSIGN } from '@/store/modules/locations/constants'

export default {
  name: 'DeleteLocationModalContent',
  mixins: [Helpers],
  data () {
    return {
      reasignLocationId: ''
    }
  },
  computed: {
    ...mapGetters({
      locationEditing: 'getSettingEditingInForm',
      locationHasClinicians: 'getLocationHasClinicians',
      locations: 'getLocations'
    }),
    filteredLocations () {
      return this.locations.filter(l => l.id !== this.locationEditing.id)
    }
  },
  destroyed () {
    this.closeModal()
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING,
      setDeleteModalOpen: MUTATION_SET_MODAL_OPENED,
      setDeleteModalTemplate: MUTATION_SET_MODAL_TEMPLATE
    }),
    deleteLocation () {
      if (this.reasignLocationId) {
        const params = { old_location: Number(this.locationEditing.id), new_location: Number(this.reasignLocationId) }
        this.$genericDispatch(LOCATION_RE_ASSIGN, params).then(() => {
          this.$toast.success({ message: this.$t('usersReAssigned') })
          this.executeDeletion()
        })
      } else {
        this.executeDeletion()
      }
    },
    executeDeletion () {
      this.$genericDispatch(DELETE_LOCATION, this.locationEditing).then(() => {
        this.$toast.success({ message: this.$t('fieldDeleted', { name: this.locationEditing.name }) })
        this.setSettingToEdit(null)
        this.closeModal()
      })
    },
    closeModal () {
      this.setDeleteModalOpen(false)
      this.setDeleteModalTemplate('')
    }
  }
}
</script>
