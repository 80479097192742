<template>
  <div class="custom-modal-content">
    <div class="custom-modal-title">
      {{ $t(title) }}
    </div>
    <div class="custom-modal-body">
      <label for="queryName">
        {{ $t('analyticsQueryModals.analyticsSaveQueryFormFieldName') }}
      </label>
      <input
        id="queryName"
        ref="queryNameInputRef"
        v-model="queryName"
        v-validate="'required|min:3|max:30'"
        type="text"
        class="form-control"
        name="queryName"
      >
      <div class="fixed-size-div">
        <span
          v-if="queryNameInUse"
          class="error"
          :class="queryNameInUseErrorStyle"
        >
          {{ $t(queryNameInUseErrorText) }}
        </span>
        <span
          v-if="errors.has('queryName')"
          class="error"
        >
          {{ errors.first('queryName') }}
        </span>
      </div>
    </div>
    <div class="custom-modal-footer">
      <button
        :disabled="!queryName"
        class="new-action-button new-button full-width-button"
        @click="saveQuery()"
      >
        {{ btnLabel }}
      </button>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  ANALYTICS_DELETE_SAVED_QUERY,
  ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION,
  ANALYTICS_SAVE_QUERY,
  ANALYTICS_UPDATE_SAVED_QUERY
} from '@/store/modules/analytics/outcomes-explorer/constants'
import { ModalMixin } from '@/mixins/modal/ModalMixin'
import { Helpers } from '@/mixins/Helpers'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'

export default {
  name: 'AnalyticsQueryFormModal',
  mixins: [Helpers, ModalMixin, ErrorsDictionary],
  data () {
    return {
      queryName: null
    }
  },
  computed: {
    ...mapGetters({
      savedQueries: 'getAnalyticsSavedQueries',
      modalConfig: 'getCustomModalConfig',
      savedQuery: 'getAnalyticsSelectedSavedQueryForEdition',
      currentQuery: 'getAnalyticsCurrentQuery'
    }),
    queryNameInUse () {
      const queryWithSameName = this.getQueryThatMatchName()
      if (queryWithSameName && this.savedQuery && queryWithSameName.id === this.savedQuery.id) {
        return false
      }

      return !!queryWithSameName
    },
    queryNameInUseErrorText () {
      return this.modalConfig.action === 'create'
        ? 'analyticsQueryModals.analyticsSaveQueryFormDuplicatedNameReplaceMsg'
        : 'analyticsQueryModals.analyticsSaveQueryFormDuplicatedNameChooseAnotherError'
    },
    queryNameInUseErrorStyle () {
      return this.modalConfig.action === 'create' ? 'warning' : ''
    },
    title () {
      return this.modalConfig.action === 'create' ? 'analyticsQueryModals.analyticsSaveQueryFormTitle' : 'analyticsQueryModals.analyticsRenameQueryFormTitle'
    },
    btnLabel () {
      return this.queryNameInUse ? this.$t('analyticsQueryModals.analyticsReplaceQuery') : this.$t('saveAndClose')
    }
  },
  created () {
    if (this.savedQuery) {
      this.queryName = this.savedQuery.name
    }

    this.$nextTick().then(() => {
      this.$refs.queryNameInputRef.focus()
    })
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
  },
  methods: {
    ...mapActions({
      createQueryAction: ANALYTICS_SAVE_QUERY,
      updateQueryAction: ANALYTICS_UPDATE_SAVED_QUERY,
      deleteSavedQueryAction: ANALYTICS_DELETE_SAVED_QUERY
    }),
    ...mapMutations({
      setSavedQueryForEdition: ANALYTICS_SET_SELECTED_QUERY_FOR_EDITION
    }),
    getQueryThatMatchName () {
      if (!this.savedQueries || !this.savedQueries.length || this.queryName === null) {
        return null
      }

      return this.savedQueries.find(i => {
        return i.name.toLowerCase() === this.queryName.toLowerCase()
      })
    },
    saveQuery () {
      this.$validator.validateAll({
        queryName: this.queryName
      }).then(valid => {
        return valid ? this.submitQuery() : false
      })
    },
    submitQuery () {
      // Update Flow just
      if (this.modalConfig.action === 'update') {
        return this.updateQuery()
      }

      const queryWithSameName = this.getQueryThatMatchName()
      const data = this.getQueryData(null)

      // Override Query Flow.
      if (queryWithSameName) {
        data.id = queryWithSameName.id
        data.favorite = queryWithSameName.favorite
        return this.executeUpdateDispatch(data, false)
      }

      // Create Query Flow.
      return this.$genericDispatch(ANALYTICS_SAVE_QUERY, data).then(() => {
        this.$toast.success({ message: this.$t('analyticsQueryModals.analyticsSaveQuerySavedToastText', { name: data.name }) })
        this.close(null)
      })
    },
    getQueryData (savedQuery) {
      return {
        query: savedQuery ? savedQuery.query : this.currentQuery,
        chart_info: savedQuery ? savedQuery.chart_info : false,
        favorite: savedQuery ? savedQuery.favorite : false,
        name: this.queryName,
        from_state: false
      }
    },
    updateQuery () {
      const queryWithSameName = this.getQueryThatMatchName()
      const updatingSelf = (queryWithSameName && this.savedQuery && queryWithSameName.id === this.savedQuery.id)

      if (!queryWithSameName || updatingSelf) {
        const loading = this.$loading.show()
        const data = this.getQueryData(this.savedQuery)
        data.id = this.savedQuery.id

        this.executeUpdateDispatch(data, loading)
      }
    },
    executeUpdateDispatch (data, loading) {
      return this.$genericDispatch(ANALYTICS_UPDATE_SAVED_QUERY, data, !loading).then(() => {
        this.$toast.success({ message: this.$t('analyticsQueryModals.analyticsSaveQueryUpdatedToastText', { name: data.name }) })
      }).finally(() => {
        if (loading) {
          loading.hide()
        }
        this.close()
      })
    }
  }
}
</script>
