import { mapMutations } from 'vuex'
import {
  MUTATION_SET_MODAL_CONFIG,
  MUTATION_SET_MODAL_OPENED
} from '@/store/modules/applicationState/constants'

export const ModalMixin = {
  mixins: [],
  computed: {},
  methods: {
    ...mapMutations({
      setModalOpened: MUTATION_SET_MODAL_OPENED,
      setModalConfig: MUTATION_SET_MODAL_CONFIG
    }),
    close () {
      this.setModalOpened(false)
      this.setModalConfig({})
    }
  }
}
