<template>
  <div class="custom-modal-content">
    <div class="custom-modal-title">
      {{ customModalConfig.title }}
    </div>
    <div
      v-if="customModalConfig"
      class="custom-modal-body"
    >
      <div v-if="showEmail">
        <CustomRadioButton
          id="contactEmail"
          ref="selectedContactEmail"
          v-model="selectedContact"
          name="selectedContact"
          :label="contactEmail.value"
          :val="contactEmail.channel"
          custom-event="blur"
          :mocked="contactEmail.mocked"
          :placeholder="$t('enterEmailAddress')"
          @focus="resetValidator"
          @changedChannelValue="changedChannelValue"
        />
        <div>
          <input
            v-if="validateEmail()"
            id="new_value_email"
            v-model="selectedContactNewValue"
            v-validate="'required|email'"
            name="new_value_email"
            type="hidden"
          >
        </div>
      </div>
      <div v-if="showPhone">
        <CustomRadioButton
          id="contactPhone"
          ref="selectedContactPhone"
          v-model="selectedContact"
          name="selectedContact"
          :label="$maskedPhoneHyphens(contactPhone.value)"
          :val="contactPhone.channel"
          :mocked="contactPhone.mocked"
          custom-event="blur"
          :placeholder="$t('enterMobile')"
          :size="20"
          @focus="resetValidator"
          @changedChannelValue="changedChannelValue"
        />
        <div>
          <input
            v-if="validatePhone()"
            id="new_value_phone"
            v-model="selectedContactNewValue"
            v-validate="'required|min:10|max:10|numeric'"
            name="new_value_phone"
            type="hidden"
          >
        </div>
      </div>
    </div>
    <div
      v-if="showContactInfoWillBeUpdated"
      class="contact-info-updated"
    >
      {{ $t('contactInfoWillBeUpdated') }}
    </div>
    <div class="custom-modal-footer">
      <div v-if="errors.any()">
        <span class="error">{{ $t('selectedContactInvalid') }}</span>
      </div>
      <div>
        <div
          class="btn btn-outline-secondary"
          @click="closeModal()"
        >
          {{ $t('cancel') }}
        </div>
        <div
          :disabled="!canSubmit"
          class="btn btn-secondary"
          @click="executeAction()"
        >
          {{ $t('send') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomRadioButton from '@/components/common/CustomRadioButton'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { Helpers } from '@/mixins/Helpers'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import { PatientHelper } from '@/mixins/PatientHelper'
import {
  MUTATION_SET_MODAL_CONFIG,
  MUTATION_SET_MODAL_OPENED,
  MUTATION_SET_MODAL_TEMPLATE,
  PATIENT_ROW_ACTIVE,
  UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA
} from '@/store/modules/applicationState/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SendMessageToPatient',
  components: { CustomRadioButton },
  mixins: [Helpers, PatientHelper, LastSessionDetail],
  data () {
    return {
      selectedContact: '',
      selectedContactNewValue: null,
      submitting: false
    }
  },
  computed: {
    ...mapGetters({
      customModalConfig: 'getCustomModalConfig'
    }),
    contactEmail () {
      if (this.customModalConfig.user && this.customModalConfig.user.contact_email) {
        return this.customModalConfig.user.contact_email
      }
      return { value: '', channel: 1, enabled: 0, mocked: true }
    },
    contactPhone () {
      if (this.customModalConfig.user && this.customModalConfig.user.contact_phone) {
        return this.customModalConfig.user.contact_phone
      }
      return { value: '', channel: 2, enabled: 0, mocked: true }
    },
    canSubmit () {
      return !(this.errors.any() || this.submitting || !this.selectedContact)
    },
    showPhone () {
      return this.userSmsEnabled(this.customModalConfig.user)
    },
    showEmail () {
      return this.userEmailEnabled(this.customModalConfig.user)
    },
    hasPhone () {
      return this.showPhone && this.contactPhone && this.contactPhone.value
    },
    selectedPhone () {
      return this.selectedContact === 2
    },
    selectedEmail () {
      return this.selectedContact === 1
    },
    willEnablePhone () {
      return this.showPhone && !(this.contactPhone.enabled) && this.selectedPhone
    },
    willEnableEmail () {
      return this.showEmail && !(this.contactEmail.enabled) && this.selectedEmail
    },
    showContactInfoWillBeUpdated () {
      return this.selectedContactNewValue || this.willEnablePhone || this.willEnableEmail
    },
    hasEmail () {
      return this.showEmail && this.contactEmail && this.contactEmail.value
    }
  },
  watch: {
    selectedContact () {
      this.changedChannelValue(this.getContactValueByChannel())
    }
  },
  created () {
    this.getInitialContactSelected()
  },
  destroyed () {
    this.closeModal()
  },
  methods: {
    ...mapMutations({
      setDeleteModalOpen: MUTATION_SET_MODAL_OPENED,
      setPatientRowActive: PATIENT_ROW_ACTIVE,
      setCustomModalConfig: MUTATION_SET_MODAL_CONFIG,
      setDeleteModalTemplate: MUTATION_SET_MODAL_TEMPLATE,
      updateContactInformation: UPDATE_CONTACT_INFORMATION_IN_MODAL_DATA
    }),
    validateEmail () {
      return this.showEmail && this.selectedEmail && this.$refs && this.$refs.selectedContactEmail && this.$refs.selectedContactEmail.editMode
    },
    validatePhone () {
      return this.showPhone && this.selectedPhone && this.$refs && this.$refs.selectedContactPhone && this.$refs.selectedContactPhone.editMode
    },
    changedChannelValue (newValue) {
      this.selectedContactNewValue = this.validatePhone() ? this.getCleanPhone(newValue) : newValue
    },
    closeModal () {
      this.setPatientRowActive(null)
      this.setDeleteModalOpen(false)
      this.setDeleteModalTemplate('')
      this.setCustomModalConfig({})
    },
    getContactValueByChannel () {
      if (this.selectedContactNewValue) {
        return this.selectedContactNewValue
      } else if (this.customModalConfig.user.contact_email && this.customModalConfig.user.contact_email.channel === this.selectedContact) {
        return this.customModalConfig.user.contact_email.value
      } else if (this.customModalConfig.user.contact_phone && this.customModalConfig.user.contact_phone.channel === this.selectedContact) {
        return this.customModalConfig.user.contact_phone.value
      }
      return ''
    },
    getInitialContactSelected () {
      const validMail = (this.showEmail && this.hasEmail)
      const validPhone = (this.showPhone && this.hasPhone)

      // Has email and Email enabled
      if (validMail && this.customModalConfig.user.contact_email.enabled === 1) {
        this.selectedContact = this.customModalConfig.user.contact_email.channel
        return true
      }

      // Has Phone and Phone Enabled.
      if (validPhone && this.customModalConfig.user.contact_phone.enabled === 1) {
        this.selectedContact = this.customModalConfig.user.contact_phone.channel
        return true
      }

      if (validMail) {
        this.selectedContact = this.customModalConfig.user.contact_email.channel
        return true
      }

      if (validPhone) {
        this.selectedContact = this.customModalConfig.user.contact_phone.channel
        return true
      }
    },
    resetValidator () {
      this.$validator.reset()
    },
    executeAction () {
      if (this.canSubmit) {
        this.$validator.validateAll().then((valid) => {
          if (valid) {
            this.submitting = true
            const params = {
              id: this.customModalConfig.user.user_id,
              channel: this.selectedContact
            }

            if (this.selectedContactNewValue) {
              params.new_channel_value = this.selectedContactNewValue
            }

            this.$genericDispatch(this.customModalConfig.action, params).then(() => {
              componentRefreshSingleton.refreshComponents()
              if (this.activeAssignment) {
                this.$updatePatientOverview()
              }

              if (this.lastSessionDetail && this.lastSessionDetail.cca_id) {
                this.openLastSessionDetail(this.lastSessionDetail, true)
              }

              this.updateContactInformation({ channel: this.selectedContact, value: this.selectedContactNewValue })
              const message = this.customModalConfig.addContactValue ? `${this.customModalConfig.successMesage}${this.getContactValueByChannel()}` : this.customModalConfig.successMesage
              this.$toast.success({ message })
            }).finally(() => {
              this.closeModal()
              this.submitting = false
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-modal-body > div{
  margin-bottom: 25px;
  cursor: pointer;
}
</style>
