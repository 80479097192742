<template>
  <div
    v-if="customModalIsOpened && customModalTemplate"
    class="custom-modal-container"
    :class="`${customClass} ${customModalConfig.customClass}`"
  >
    <div class="custom-modal-box">
      <i
        v-if="showCloseIcon"
        class="fal fa-times closeIcon"
        @click.stop="closeModal()"
      />
      <component
        :is="customModalTemplate"
        v-if="customModalTemplate"
        ref="customModalComponent"
      />
    </div>
  </div>
</template>

<script>
import EnhanceAuditionModalContent from './templates/enhanceAuditionModalContent'
import DeleteLocationModalContent from './templates/DeleteLocationModalContent'
import SendMessageToPatient from './templates/SendMessageToPatient'
import AnalyticsDeleteSavedQueryModal from './templates/AnalyticsDeleteSavedQueryModal'
import AnalyticsQueryFormModal from './templates/AnalyticsQueryFormModal'

import { mapGetters } from 'vuex'

export default {
  name: 'ModalWrapper',
  components: {
    EnhanceAuditionModalContent,
    DeleteLocationModalContent,
    SendMessageToPatient,
    AnalyticsDeleteSavedQueryModal,
    AnalyticsQueryFormModal
  },
  props: ['hideCloseIcon', 'customClass'], // eslint-disable-line vue/require-prop-types
  computed: {
    ...mapGetters({
      customModalConfig: 'getCustomModalConfig',
      customModalTemplate: 'getCustomModalTemplate',
      customModalIsOpened: 'getCustomModalIsOpened'
    }),
    opened () {
      return !!this.customModalIsOpened && !!this.customModalTemplate
    },
    showCloseIcon () {
      return !this.hideCloseIcon && !this.customModalConfig.hideCloseIcon
    }
  },
  watch: {
    opened (newValue) {
      if (newValue) {
        document.body.classList.add('overlay-opened')
      } else {
        document.body.classList.remove('overlay-opened')
      }
    }
  },
  beforeDestroy () {
    document.body.classList.remove('overlay-opened')
  },
  methods: {
    closeModal () {
      this.$refs.customModalComponent.close()
    }
  }
}
</script>
