<template>
  <div>
    <input
      v-show="false"
      v-model="radioButtonValue"
      type="radio"
      :value="val"
      :name="name"
    >
    <div class="custom-radio-button">
      <i
        :class="{'far fa-dot-circle': radioButtonValue === val, 'fal fa-circle': radioButtonValue !== val}"
        @click="selectionChange(val)"
      />
      <span v-show="!editMode">{{ label }}</span>
      <div
        v-show="!editMode"
        class="btn btn-outline-secondary btn-owl-mini-edit"
        @click="enableEditMode"
      >
        EDIT
      </div>
      <input
        v-show="editMode"
        v-model="newValue"
        :size="size"
        class="custom-radio-button-text-input"
        :placeholder="placeholder"
        type="text"
        @focus="setAsSelectedOption"
        @keyup="setUpdatedValue"
        @blur="setUpdatedValue"
      >
      <i
        v-show="showCloseEditMode"
        class="fal fa-times custom-radio-button-text-input-close"
        @click="disableEditMode"
      />
    </div>
  </div>
</template>
<script>

export default {
  name: 'CustomRadioButton',
  props: ['name', 'val', 'value', 'label', 'placeholder', 'size', 'customEvent', 'mocked'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      originalValue: null,
      editMode: !this.label,
      newValue: null
    }
  },
  computed: {
    showCloseEditMode () {
      if (this.editMode && (!this.originalValue || this.mocked)) {
        return false
      }
      return this.editMode
    },
    radioButtonValue: {
      get: function () {
        return this.value
      },
      set: function () {
        this.$emit('input', this.val)
      }
    }
  },
  created () {
    this.originalValue = this.label
    this.newValue = this.label
  },
  methods: {
    setAsSelectedOption () {
      this.$emit('focus')
      this.radioButtonValue = this.val
    },
    enableEditMode () {
      this.editMode = true
      if (this.$attrs.id === 'contactEmail' || this.$attrs.id === 'contactPhone') {
        this.$nextTick(() => {
          document.querySelector('.custom-radio-button-text-input').focus()
        })
      }
    },
    disableEditMode () {
      this.revertValueChanges()
      this.editMode = false
    },
    revertValueChanges () {
      this.newValue = this.originalValue
      this.$emit('changedChannelValue', null)
    },
    setUpdatedValue (evt) {
      if (this.customEvent) {
        if (evt.type === this.customEvent) {
          this.$emit('changedChannelValue', this.newValue)
        }
      } else {
        this.$emit('changedChannelValue', this.newValue)
      }
    },
    selectionChange () {
      this.radioButtonValue = this.val
      if (this.label) {
        this.disableEditMode()
      }
      this.$emit('input', this.val)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-radio-button {
  display: flex;
  align-items: center;
  span {
    width: 310px;
    overflow: hidden;
    margin: 0 5px;
  }
}
label {
  span:hover,
  i:hover {
    cursor: pointer;
  }
}
</style>
